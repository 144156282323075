<template>
  <base-layout>
    <page-section>
      <div class="flex items-center justify-between mt-8">
        <div class="flex items-center">
          <oto-page-title title="Organization User Permissions" />
        </div>
      </div>

      <!-- start: table-view -->
      <div class="py-8 mx-auto">
        <!-- start: search & filter -->
        <div class="flex flex-col my-2 mb-5 ml-1 sm:flex-row">
          <oto-search v-model="search" />
        </div>
        <!-- end: search & filter -->

        <!-- start: table-container -->
        <fsTableContainer class="vld-parent">
          <loading :active.sync="isLoading" :is-full-page="false" />

          <fsTable>
            <fsTableHead>
              <fsTableHeadItem
                v-for="(item, index) in tableHeaders"
                :key="`fs-table-header-${index}`"
                :item="item"
                type="permission"
              />
            </fsTableHead>

            <fsTableRow
              v-for="(item, index) in permissions"
              :key="`fs-table-row-${index}`"
            >
              <fsTableRowItem type="permission">
                {{ item.name }}
              </fsTableRowItem>
              <fsTableRowItem
                type="permission"
                v-for="role in item.child"
                :key="`role-row-${role.id}`"
              >
                <t-toggle
                  :checked="role.status ? true : false"
                  @change="onChangeToggle(index, role)"
                  variant="success"
                />
              </fsTableRowItem>
            </fsTableRow>
          </fsTable>
        </fsTableContainer>
        <!-- end: table-container -->
      </div>
      <!-- end: table-view -->
    </page-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import PageSection from '@/components/layout/PageSection'
import OtoPageTitle from '@/components/ui/OtoPageTitle'

import fsTableUiMixin from '@/mixins/fsTableUiMixin'

// SubscriptionConfig
import { PermissionConfig } from '@/config/PermissionConfig'
import { RoleConfig } from '@/config/RoleConfig'
import roleApi from '@/config/api/role'
import OtoSearch from '@/components/ui/OtoSearch'
// import OtoStatus from "@/components/ui/OtoStatus";
import xMan from '@/utils/xMan'

export default {
  name: 'PermissionsManagement',
  mixins: [fsTableUiMixin],
  components: {
    BaseLayout,
    PageSection,
    OtoPageTitle,
    OtoSearch,
    //OtoStatus
  },
  data() {
    return {
      isLoading: false,
      permissions: [],
      org_permissions: [],
      search: '',
      tableHeaders: [],
    }
  },
  async mounted() {
    this.isLoading = true
    let permissions = await this.$http
      .get(PermissionConfig.api.index)
      .then((res) => res.data.data)
      .catch((err) => console.log('err = ', err))

    let roles = await this.$http
      .get(RoleConfig.api.index + '?permissions')
      .then((res) => res.data.data)
      .catch((err) => {
        console.log('err = ', err)
      })

    this.roles = roles
    this.org_permissions = permissions

    if (
      this.roles &&
      this.roles.length != 0 &&
      this.org_permissions &&
      this.org_permissions.length != 0
    ) {
      this.getTableHeader()
      this.getAllPermissions()
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  },
  methods: {
    getTableHeader() {
      var arr = []

      var permission_header = {
        name: 'Permission',
        width: `${100 / (this.roles.length + 1)}%`,
        field: 'id',
        sort: null,
        order: '',
      }
      arr.push(permission_header)

      this.roles.forEach((role) => {
        var obj = {
          name: role.role_name,
          width: `${100 / (this.roles.length + 1)}%`,
          field: role.id,
          sort: null,
          order: '',
        }
        arr.push(obj)
      })
      this.tableHeaders = arr
    },

    getAllPermissions() {
      var arr = []
      this.org_permissions.forEach((permission) => {
        var obj = {
          id: permission.id,
          name: permission.name,
          child: [],
        }
        this.roles.forEach((role) => {
          var count = 0
          role.permissions.forEach((rol_per) => {
            if (obj.id == rol_per.id) {
              obj.child.push({
                id: role.id,
                name: role.role_name,
                status: true,
              })
              count = -1
            }
          })
          if (count == 0) {
            obj.child.push({
              id: role.id,
              name: role.role_name,
              status: false,
            })
          }
        })
        arr.push(obj)
      })
      this.permissions = arr
    },

    onChangeToggle(index, role) {
      let childs = this.permissions[index].child
      childs.forEach((child) => {
        if (child.id == role.id) {
          child.status = !child.status
        }
      })
      this.permissions[index].child = childs
      this.updateRole(role, this.permissions[index].id)
    },

    async updateRole(role, pId) {
      let role_data = null
      this.roles.forEach((item) => {
        if (item.id == role.id) {
          role_data = item
        }
      })
      if (role_data != null) {
        let result = []
        role_data.permissions.forEach((permission) => {
          this.org_permissions.forEach((org_permission) => {
            if (permission.id == org_permission.id) {
              result.push(org_permission)
            }
          })
        })
        if (role.status) {
          this.org_permissions.forEach((org_permission) => {
            if (pId == org_permission.id) {
              result.push(org_permission)
            }
          })
        } else {
          result.forEach((item, index) => {
            if (item.id == pId) {
              result.splice(index, 1)
            }
          })
        }
        var formData = new xMan().toFormData()

        formData.append('role_name', role.name)
        result.forEach((item) => {
          formData.append('permissions', item.id)
        })

        roleApi.data = formData

        try {
          this.isLoading = true
          let response = await this.$http.patch(
            roleApi.update(role.id),
            roleApi.data
          )
          const title = role.status
            ? 'Permission Invoked'
            : 'Permission Revoked'

          const message = role.status
            ? 'Permission Invoked Successfully.'
            : 'Permission Revoked Successfully.'

          this.isLoading = false
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: title,
              text: message,
            },
            3500
          )
          return response.data
        } catch (error) {
          alert(error.message)
          this.isLoading = false
          return error.message
        }
      }
    },
  },
}
</script>

<style></style>
