<template>
  <slide-over-right :id="sorId" editType="roles">
    <loading :active.sync="isLoading"></loading>

    <template v-slot:header>
      <h2 class="font-semibold text-gray-900 text-22px">
        {{ isEditing ? 'Edit' : 'Add' }} Role
      </h2>
    </template>

    <template v-slot:footer>
      <div class="flex items-center justify-end mt-6">
        <t-button variant="secondary" @click="dismiss">Cancel</t-button>
        <t-button
          type="submit"
          @click="submit()"
          :disabled="form.role_name === 'Owner'"
          class="ml-3"
          >Save</t-button
        >
      </div>
      <!-- <div class="flex items-center justify-end mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">Cancel</anchor-button>
        </slide-over-right-trigger>

      </div> -->
    </template>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="block px-6" @submit.prevent="handleSubmit(confirmSubmit)">
        <div class="sticky top-0 left-0 z-10">
          <div class="flex flex-wrap w-full">
            <text-input
              rules="required"
              label="Role name"
              v-model="form.role_name"
            />
          </div>
          <fsTableContainer>
            <fsTable>
              <fsTableHead>
                <fsTableHeadItem
                  v-for="(item, index) in tableHeaders"
                  :key="`fs-table-header-${index}`"
                  :item="item"
                  type="permission"
                  :className="item.className"
                />
              </fsTableHead>
            </fsTable>
          </fsTableContainer>
        </div>

        <div class="grid grid-cols-1 mt-5 ">
          <!-- start: table-container -->
          <fsTableContainer>
            <loading :active.sync="isLoading" :is-full-page="false" />

            <fsTable>
              <fsTableRow
                v-for="(item, index) in permissions"
                :key="`fs-table-row-${index}`"
              >
                <fsTableRowItem type="permission" className="text-left">
                  {{ item.name }}
                </fsTableRowItem>
                <fsTableRowItem
                  v-for="(actionType, actionTypeIndex) in actionTypes"
                  :key="
                    `action-type-row-${item.action_types.includes(
                      actionType
                    )}-${index}-${actionTypeIndex}`
                  "
                  type="permission"
                >
                  <t-toggle
                    v-if="
                      (availableActionsMap[item.name] || []).includes(
                        actionType
                      ) && actionType === 'VIEW'
                    "
                    :checked="
                      item.action_types.includes(actionType) ? true : false
                    "
                    @change="onChangeToggle(index, actionType)"
                    variant="success"
                  />
                  <t-toggle
                    v-else-if="
                      (availableActionsMap[item.name] || []).includes(
                        actionType
                      ) && actionType !== 'VIEW'
                    "
                    :checked="
                      item.action_types.includes(actionType) ? true : false
                    "
                    :disabled="
                      permissions[index].action_types.includes('VIEW')
                        ? false
                        : true
                    "
                    @change="onChangeToggle(index, actionType)"
                    variant="success"
                  />
                  <t-toggle
                    v-else
                    :checked="
                      item.action_types.includes(actionType) ? true : false
                    "
                    :disabled="
                      !(availableActionsMap[item.name] || []).includes(
                        actionType
                      )
                    "
                    variant="success"
                  />
                </fsTableRowItem>
              </fsTableRow>
            </fsTable>
          </fsTableContainer>
          <!-- end: table-container -->
        </div>

        <input type="submit" value="Submit" ref="submitButton" class="hidden" />
      </form>
    </ValidationObserver>
  </slide-over-right>
</template>

<script>
//<t-checkbox :checked="permission.status ? true : false " class="mt-1" />
import roleApi from '@/config/api/role'
import permissionApi from '@/config/api/permission'
import xMan from '@/utils/xMan'
import TextInput from '@/components/form/TextInput'

import SlideOverRight from '@/components/modals/SlideOverRight'
// import SlideOverRightTrigger from "@/components/modals/SlideOverRightTrigger";

import { EventBus } from '@/utils/EventBus'
import { RoleConfig } from '@/config/RoleConfig'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'

export default {
  name: 'AddTaxPlan',
  mixins: [fsTableUiMixin],
  components: {
    SlideOverRight,
    // SlideOverRightTrigger,
    TextInput,
  },
  data() {
    return {
      isLoading: false,
      form: {},
      permissions: [],
      p_arr: [],
      sorId: RoleConfig.events.sorId,
      item: {},
      availableActionsMap: {},
      actionTypes: ['VIEW', 'CREATE', 'UPDATE', 'DELETE'],
      tableHeaders: [
        {
          name: 'Permission',
          width: '32%',
          field: 'id',
          sort: null,
          order: '',
          className: 'text-left',
        },
        {
          name: 'View',
          width: '17%',
          field: 'VIEW',
          sort: null,
          order: '',
        },
        {
          name: 'Create',
          width: '17%',
          field: 'CREATE',
          sort: null,
          order: '',
        },
        {
          name: 'Update',
          width: '17%',
          field: 'UPDATE',
          sort: null,
          order: '',
        },
        {
          name: 'Delete',
          width: '17%',
          field: 'DELETE',
          sort: null,
          order: '',
        },
      ],
    }
  },
  async created() {
    this.getPermissions()
  },
  computed: {
    isEditing: function() {
      // enabled status-switch by default
      return Object.keys(this.item).length > 1
    },
  },
  mounted() {
    EventBus.$on(RoleConfig.events.editingData, (item) => {
      this.item = item
      if (item.id) {
        this.isLoading = true
        this.$http
          .get(RoleConfig.api.detail(item.id))
          .then((res) => {
            let apiPermissions = res.data.permissions

            // clear previous data first
            this.permissions.forEach((element) => {
              element.action_types = []
            })

            apiPermissions.forEach((item) => {
              this.permissions.forEach((element) => {
                if (item.id == element.id) {
                  element.action_types = item.action_types
                }
              })
            })
            this.form = {
              role_name: item.role_name,
            }
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.form.role_name = ''
        this.permissions.forEach((element) => {
          element.action_types = []
        })
      }
    })
  },
  methods: {
    dismiss() {
      console.log('dismiss')
      dispatchEvent(new Event(RoleConfig.events.sorToggle))
    },
    async confirmSubmit() {
      this.isLoading = true
      var formData = new xMan().toFormData()
      formData.append('role_name', this.form.role_name)
      this.permissions.forEach((element) => {
        if (element.action_types && element.action_types.length) {
          formData.append(
            'permissions',
            `${element.id}:${element.action_types.toString()}`
          )
        }
      })

      roleApi.data = formData

      try {
        let response = null
        if (this.isEditing) {
          response = await this.$http.patch(
            roleApi.update(this.item.id),
            roleApi.data
          )
        } else {
          response = await this.$http.post(roleApi.create, roleApi.data)
        }

        this.isLoading = false
        dispatchEvent(new Event(RoleConfig.events.sorToggle))
        dispatchEvent(new Event(RoleConfig.events.refresh))
        this.form = {}

        return response.status
      } catch (error) {
        // todo: remove logs
        console.log(error)
        this.isLoading = false
        dispatchEvent(
          new Event(this.getClosingEventName(RoleConfig.events.sorClose))
        )
        throw new Error(error.status)
      }
    },

    submit: async function() {
      this.$refs.submitButton.click()
    },

    getPermissions: async function() {
      this.isLoading = true
      try {
        // populate available actions
        let response = await this.$http.get(permissionApi.availableActions)
        let data = response.data.data
        data.forEach((item) => {
          this.availableActionsMap[item.permission_name] =
            item.available_actions
        })

        response = await this.$http.get(permissionApi.index)
        data = response.data.data
        const result = []
        data.forEach((item) => {
          const obj = {
            id: item.id,
            action_types: [],
            name: item.name,
          }
          result.push(obj)
        })
        this.permissions = result
        this.isLoading = false
      } catch (error) {
        // todo: remove logs
        console.log(error)
        this.isLoading = false
        throw new Error(error.status)
      }
    },

    onChangeToggle(index, actionType) {
      if (this.permissions[index].action_types.includes(actionType)) {
        if (actionType === 'VIEW') {
          this.permissions[index].action_types = []
        } else {
          this.permissions[index].action_types = this.permissions[
            index
          ].action_types.filter((x) => x !== actionType)
        }
      } else {
        this.permissions[index].action_types.push(actionType)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky;
  background-color: white;
}
</style>
