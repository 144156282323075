export const PermissionConfig = {
  api: {
    // get
    index: '/dashboard/permissions/',
    // post
  },
  events: {
    name: 'permission',
    // refresh-index-data
    refresh: `rid-permission`,
    // slideover-right
    sorId: 'permission',
  },
}
