<template>
  <base-layout>
    <add-role />
    <content-section :spacing="false">
      <!--
      <div class="flex justify-between ">
        <div>sf</div>
        <MoreActionsDropdown
          currentVal="D"
          onOffMethod="PATCH"
          onOffEndpoint="/test"
          :onOffEnabled="true"
          actionOnText="Activate"
          actionOnKey="status"
          actionOnVal="A"
          actionOffText="Deactivate"
          actionOffKey="status"
          actionOffVal="D"
          actionHoldText="Hold"
          actionHoldKey="status"
          actionHoldVal="H"
          actionHoldMethod="POST"
          actionHoldEndpoint="/hold"
          :actionHoldEnabled="true"
          actionExtraText="Ring"
          actionExtraKey="ring"
          actionExtraVal="E"
          actionExtraMethod="PUT"
          actionExtraEndpoint="/extra"
          :actionExtraEnabled="true"
          actionDeleteText="Delete"
          actionDeleteKey="deleted_at"
          actionDeleteVal="now"
          actionDeleteMethod="DELETE"
          actionDeleteEndpoint="/delete"
          :actionDeleteEnabled="true"
        />
      </div>
      -->
      <div class="content-spacing">
        <title-plus title="Roles" @plus="add" class="mb-10" />

        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <oto-search v-model="search" />
          </div>
          <div class="flex md:justify-end ">
            <export-as-dropdown />
          </div>
        </div>
      </div>
      <fsTableContainer class="px-2 vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false" />
        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem>
              <a class="text-blue-600" href="#" @click.prevent="edit(item)">{{
                item.id
              }}</a>
            </fsTableRowItem>

            <fsTableRowItem>{{ item.role_name }}</fsTableRowItem>

            <fsTableRowItem>
              {{ item.created_by ? item.created_by.full_name : '--' }}
              -
              {{ item.created_by ? item.created_by.role_name : '--' }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.created_at | friendlyDateTime }}
            </fsTableRowItem>

            <fsTableRowItem>
              <div class="flex items-center">
                <oto-edit-icon @click="edit(item)" />
              </div>
            </fsTableRowItem>
          </fsTableRow>
        </fsTable>

        <!-- fs-table-footer -->
        <!-- todo: change the hardcoded total-items & add paginationSummary -->
        <fs-table-foot :total-items="20" />
        <!-- fs-table-footer -->
      </fsTableContainer>
    </content-section>
  </base-layout>
</template>

<script>
import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'

import roleApi from '@/config/api/role'

// Components
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import OtoSearch from '@/components/ui/OtoSearch'

import AddRole from './RoleAddEdit'
// import MoreActionsDropdown from "@/components/dropdown/MoreActionsDropdown.vue";

import { EventBus } from '@/utils/EventBus'
import { RoleConfig } from '@/config/RoleConfig'
export default {
  name: 'Roles',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    fsTableUiMixin,
    getIndexDataMixin,
  ],
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    ExportAsDropdown,
    OtoSearch,
    AddRole,
    OtoEditIcon,
    // MoreActionsDropdown,
  },
  data() {
    return {
      indexDataEndpoint: roleApi.index,
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      tableHeaders: [
        {
          name: 'ID',
          width: '15%',
          field: 'id',
          sort: false,
          order: '',
        },
        {
          name: 'Name',
          width: '15%',
          field: 'role_name',
          sort: false,
          order: '',
        },
        {
          name: 'Created By',
          width: '25%',
          field: 'created_by',
          sort: null,
          order: '',
        },
        {
          name: 'Created At',
          width: '25%',
          field: 'created_at',
          sort: null,
          order: '',
        },
        {
          name: 'Actions',
          width: '20%',
          field: null,
          sort: null,
          order: '',
        },
      ],
    }
  },
  mounted() {
    window.addEventListener(RoleConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  methods: {
    handleDeletion: function(e) {
      console.log({ delete: e })
    },
    add() {
      EventBus.$emit(RoleConfig.events.editingData, {})
      dispatchEvent(new Event(RoleConfig.events.sorToggle))
    },
    edit(item) {
      EventBus.$emit(RoleConfig.events.editingData, item)
      dispatchEvent(new Event(RoleConfig.events.sorToggle))
    },
  },
  // todo: refresh (re-fetch) data after listening event
  // todo: show toast
}
</script>
